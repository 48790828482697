import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="relative">
      <StaticImage
        src="../images/walnutglen.jpg"
        className=""
        height={750}
        width={1200}
        placeholder="blurred"
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Hero Picture"
      />
      <div className="absolute bottom-20 right-20 p-6 md:flex md:flex-col hidden bg-white lg:w-1/3 w-1/4">
        <p className="text-primary lg:text-3xl text-lg font-bold mb-4">Proudly serving the Waterloo Region.</p>
        <button onClick={() => window.location.href='#contact'} className="px-4 py-2 border border-gray-900 text-gray-900 hover:bg-gray-100">Contact Us</button>
      </div>
    </div>
    <div className="divide-y divide-gray-300 divide-solid px-6 py-2">
      <div id="services" className="w-full my-2 flex flex-col">
        <h1 
          className="text-xl lg:text-4xl md:text-3xl text-primary font-semibold max-w-4xl px-4 py-6"
        >
          Comprehensive solutions for your general contracting, project management, and landscaping needs.
        </h1>
        <div className="flex md:flex-row flex-col">
          <div className="flex flex-col p-4 m-2 bg-gray-100 border">
            <StaticImage
              src="../images/construction.JPG"
              height={300}
              width={300}
              className=""
              placeholder="blurred"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Construction"
            />
            <div className="flex flex-col mt-2">
              <p className="lg:text-3xl text-xl font-semibold">General Contracting</p>
              <p className="max-w-xl">Theres no job too big and no job too small. We offer a wide variety of high quality contracting services.</p>
            </div>
          </div>
          <div className="flex flex-col p-4 m-2 bg-gray-100 border">
            <StaticImage
              src="../images/projectmanagement.jpg"
              height={300}
              width={300}
              placeholder="blurred"
              className=""
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Project Management"
            />
            <div className="flex flex-col md:pr-6 mt-2">
              <p className="lg:text-3xl text-xl font-semibold">Project Management</p>
              <p className="max-w-xl">At Heatherbrae, we will work closely with you to make sure your project runs smoothly from start to finish.</p>
            </div>
          </div>
          <div className="flex flex-col p-4 m-2 bg-gray-100 border">
            <StaticImage
              src="../images/bobcat.JPG"
              height={300}
              width={300}
              placeholder="blurred"
              className=""
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="A bobcat"
            />
            <div className="flex flex-col mt-2">
              <p className="lg:text-3xl text-xl font-semibold">Landscaping</p>
              <p className="max-w-xl">We offer a variety of landscaping services from sod and mulching all the way to full backyard design and build.</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col p-4 m-2 bg-gray-100 border">
          <p className="lg:text-xl text-lg font-semibold">Below are just some of the services we offer:</p>
          <ul className="list-inside list-disc columns-2 sm:max-h-20 flex flex-col flex-wrap">
            <li>Buildings</li>
            <li>Garages</li>
            <li>Homes</li>
            <li>Renovations</li>
            <li>Concrete Pads</li>
            <li>Soffit and Facia</li>
            <li>Exterior Siding</li>
            <li>Metal Cladding</li>
            <li>Railings</li>
            <li>Flooring</li>
            <li>Drywall</li>
          </ul>
        </div>
      </div>
      <div id="about" className="w-full my-2 flex flex-col items-center my-2">
        <h1 className="text-lg lg:text-3xl md:text-2xl font-semibold m-2">About Us</h1>
        <p className="text-md max-w-3xl">Operating in Waterloo Region, Heatherbrae Contracting is committed to providing superior construction and landscaping services focused on quality, value and customer satisfaction. We achieve this by maintaining clear communication, integrity, hard work, and honesty.</p>
      </div>
      <div id="gallery" className="w-full flex flex-col items-center my-2">
        <h1
          className="text-lg lg:text-3xl md:text-2xl m-2 font-semibold"
        >
          Gallery
        </h1>
        <div className="flex flex-row flex-wrap justify-center">
          <StaticImage
            src="../images/walnutglen.jpg"
            className="m-1"
            height={300}
            width={300}
            placeholder="blurred"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A building"
          />
          <StaticImage
            src="../images/barnfloor.JPG"
            className="m-1"
            height={300}
            width={300}
            placeholder="blurred"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Barn Floor"
          />
          <StaticImage
            src="../images/maple-trail1.JPG"
            className="m-1"
            height={300}
            width={300}
            placeholder="blurred"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A building"
          />
          <StaticImage
            src="../images/maple-trail2.JPG"
            className="m-1"
            height={300}
            width={300}
            placeholder="blurred"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A building"
          />
          <StaticImage
            src="../images/maple-trail3.JPG"
            className="m-1"
            height={300}
            width={300}
            placeholder="blurred"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A building"
          />
          <StaticImage
            src="../images/shakespeare.JPG"
            className="m-1"
            height={300}
            width={300}
            placeholder="blurred"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A building"
          />
          <StaticImage
            src="../images/shakespearesod.JPG"
            className="m-1"
            height={300}
            width={300}
            placeholder="blurred"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A building"
          />
          <StaticImage
            src="../images/walnutglen.jpg"
            className="m-1"
            height={300}
            width={300}
            placeholder="blurred"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A building"
          />
          <StaticImage
            src="../images/walnutglen2.JPG"
            className="m-1"
            height={300}
            width={300}
            placeholder="blurred"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A building"
          />
          <StaticImage
            src="../images/weimarsod.JPG"
            className="m-1"
            height={300}
            width={300}
            placeholder="blurred"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A building"
          />
          <StaticImage
            src="../images/kitchen2.JPG"
            className="m-1"
            height={300}
            width={300}
            placeholder="blurred"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A building"
          />

        </div>
      </div>
      <div id="contact" className="w-full flex flex-col items-center my-2">
        <h1
          className="text-lg lg:text-3xl md:text-2xl font-semibold"
        >
          Contact Us
        </h1>
        <div className="flex flex-col">
          <div className="flex flex-row">
            <p className="font-bold text-md mx-2">Email: </p>
            <a className="font-medium text-md" href="mailto:heatherbraecontracting@gmail.com">heatherbraecontracting@gmail.com</a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
